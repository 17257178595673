<template>
  <div class="form-group">
    <label :for="label">{{ label }}</label>
    <input
      class="form-control"
      :id="label"
      v-mask="mask"
      @change="onChange"
      :placeholder="placeholder"
      @input="onInputChange"
      v-model="inputValue"
      :required="Boolean(required)"
      :disabled="Boolean(disabled)"
    />
  </div>
</template>
<script>
/**
 * Component yang digunakan untuk mengisi input dengan mask tertentu.
 * @example @/docs/ExampleBaseInputMask.md
 */
export default {
  name: "BaseInputMask",
  props: {
    /**
     * Nama Label Input Mask
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Type / Bentuk Mask
     */
    mask: {
      type: String,
      required: true,
    },
    /**
     * Placeholder Input Mask
     */
    placeholder: {
      type: String,
    },
    /**
     * Value / Nilai Input Currency
     */
    value: {
      type: String,
      required: true,
    },
    /**
     * Function ketika terdapat perubahan pada Input Mask
     */
    change: {
      type: Function,
    },
    /**
     * Flag penanda inputan harus diinput / tidak
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag enabled / disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    },
  },
  methods: {
    /**
     * Trigger ketika ada perubahan pada value
     * @public
     * @event onInputChange
     * @param {-}
     */
    onInputChange: function () {
      /**
       * Update parent value.
       * @event update:value
       * @property {string} inputValue - Nilai pada Input Mask.
       */
      this.$emit("update:value", this.inputValue);
    },
    onChange: function () {
      if (this.change) {
        this.change();
      }
    },
  },
};
</script>