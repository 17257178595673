<template>
  <div class="konfigurasi-marketer">
    <CCard>
      <CCardHeader>Add Marketer</CCardHeader>
      <CCardBody>
        <form @submit.prevent="onClickAdd">
          <CRow>
            <CCol sm="6">
              <CInput label="Nama" v-model="selectedMarketer.nama" required />
            </CCol>
            <CCol sm="6" class="align-self-center text-right">
              <b-button variant="success" type="submit">
                <b-icon icon="plus" scale="2" class="mr-2" />
                Add Marketer
              </b-button>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Email"
                type="email"
                v-model="selectedMarketer.email"
                required
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="No Handphone"
                type="tel"
                v-model="selectedMarketer.noHandphone"
                required
              />
            </CCol>
          </CRow>
        </form>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader> Import Marketing Agent </CCardHeader>
      <form @submit.prevent="onClickProsesImport">
        <CCardBody>
          <CRow>
            <CCol sm="6">
              <CInputFile
                class="choose-file-input"
                label="Upload File"
                type="file"
                accept=".xlsx, .xls"
                @change="onChangeUploadFile"
                placeholder="Choose File"
                :required="Boolean(!fileUpload)"
              />
              <p class="note">Format File dalam bentuk xlsx / xls</p>
            </CCol>
            <CCol sm="6">
              <a :href="linkTemplate" target="_blank" class="download-template"
                >Download Template Import Marketing Agent</a
              >
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <b-button variant="primary" type="submit">
                <b-icon icon="gear-fill" class="mr-2" />
                Process
              </b-button>
            </CCol>
          </CRow>
        </CCardBody>
      </form>
    </CCard>
    <CCard>
      <CCardHeader>Daftar Marketer</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <BaseVueSelect
              nama-label-browse="Status Aktif"
              :data-selected.sync="selectedStatusAktif"
              :array-options="arrayStatusAktif"
            />
          </CCol>
          <CCol sm="6" class="align-self-center text-right">
            <b-button variant="primary" @click="onClickFilter">
              <b-icon icon="filter" class="mr-2" /> Filter
            </b-button>
            <b-button class="ml-2" variant="primary" @click="onClickExport">
              <b-icon icon="printer" class="mr-2" /> Export
            </b-button>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <div class="base-smart-table">
              <div v-if="!reqTableDoneMarketer">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  label="Large Spinner"
                ></b-spinner>
              </div>
              <div v-else>
                <label>Search:</label>
                <input
                  class="form-control"
                  v-model="filters.detailsTableMarketer.value"
                  :disabled="detailsTableMarketer.length == 0"
                />
                <br />
                <v-table
                  ref="table-mhs"
                  class="table-hover"
                  :data="detailsTableMarketer"
                  :currentPage.sync="currentPage"
                  :pageSize="pageSize"
                  :filters="filters"
                  selectedClass="table-info"
                  @totalPagesChanged="totalPages = $event"
                >
                  <thead slot="head">
                    <v-th
                      v-for="it in headersTableMarketer"
                      :key="it.key"
                      :sortKey="it.key"
                      >{{ it.value }}</v-th
                    >
                    <th>Link Bitly</th>
                    <th>Refresh / Update</th>
                    <th>Edit</th>
                  </thead>
                  <tbody slot="body" slot-scope="{ displayData }">
                    <template v-if="detailsTableMarketer.length > 0">
                      <v-tr v-for="row in displayData" :key="row.id" :row="row">
                        <td
                          v-for="item in headersTableMarketer"
                          :key="item.key + ' X ' + row[item.key]"
                        >
                          {{ row[item.key] }}
                        </td>
                        <td v-if="row['link_bitly']">
                          <a :href="row['link_bitly']" target="_blank">{{
                            row["link_bitly"]
                          }}</a>
                        </td>
                        <td v-else>-</td>
                        <td>
                          <b-button
                            variant="primary"
                            @click="onClickRegisterUpdateLink(row)"
                          >
                            {{ buttonRegisUpdateLinkText(row["link_bitly"]) }}
                          </b-button>
                        </td>
                        <td>
                          <b-button variant="primary" @click="onClickEdit(row)">
                            Edit
                          </b-button>
                        </td>
                      </v-tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td :colspan="headersTableMarketer.length + 3">
                          Tidak ada Data
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </v-table>
                <br />
                <smart-pagination
                  :maxPageLinks="5"
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                  :boundaryLinks="boundaryLinks"
                />
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <form @submit.prevent="doUpdateLink">
      <CModal
        title="Custom Link Bitly"
        color="primary"
        :show.sync="showModalCustomBitly"
      >
        <CInput
          label="Custom Path Link Bitly"
          v-model="path"
          placeholder="ex. Kshfksbab123"
          required
        />
        <template slot="footer-wrapper">
          <div class="text-right mb-2 mr-2">
            <b-button variant="primary" type="submit">Update Link</b-button>
          </div>
        </template>
      </CModal>
    </form>
    <form @submit.prevent="doUpdateMarketer">
      <CModal
        title="Edit Marketer"
        color="primary"
        :show.sync="showModalEditMarketer"
      >
        <CInput label="Nama Marketer" v-model="updatedMarketer.nama" required />
        <CInput
          label="Email Marketer"
          v-model="updatedMarketer.email"
          required
        />
        <CInput
          label="Handphone Marketer"
          v-model="updatedMarketer.hp"
          required
        />
        <CInputCheckbox
          label="Status Aktif"
          :checked.sync="updatedMarketer.status"
        />
        <template slot="footer-wrapper">
          <div class="text-right mb-2 mr-2">
            <b-button variant="primary" type="submit">Update Marketer</b-button>
          </div>
        </template>
      </CModal>
    </form>
  </div>
</template>
<script>
import BaseInputMask from "@/components/base/BaseInputMask.vue";
import BaseVueSelect from "@/components/base/BaseVueSelect.vue";

import MarketerApi from "@/api/MarketerApi.js";

export default {
  name: "KonfigurasiMarketerPage",
  components: {
    BaseInputMask,
    BaseVueSelect,
  },
  data() {
    return {
      // untuk add marketer
      selectedMarketer: {
        nama: "",
        email: "",
        noHandphone: "",
      },

      // untuk import marketing massal
      fileUpload: null,

      // untuk update link bitly marketer (custom link bitly)
      idMarketer: "",
      showModalCustomBitly: false,
      path: "",

      // untuk update data marketer
      showModalEditMarketer: false,
      updatedMarketer: {},
      disabledAddButton: true,

      arrayStatusAktif: ["Aktif", "Tidak Aktif"],
      selectedStatusAktif: "Aktif",

      // untuk data table
      headersTableMarketer: [
        { key: "nama", value: "Nama" },
        { key: "hp", value: "No Handphone" },
        { key: "email", value: "Email" },
        { key: "kode_referral", value: "Kode Referral" },
        // { key: "link_bitly", value: "" },
        // { key: "update_link", value: "" },
        // { key: "status", value: "" },
      ],

      detailsTableMarketer: [],
      filters: {
        detailsTableMarketer: { value: "", custom: this.functionFilterTable },
      },
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      boundaryLinks: true,
      reqTableDoneMarketer: true,
    };
  },
  computed: {
    linkTemplate: function () {
      return MarketerApi.getTemplateImportMarketingAgent();
    },
  },
  created() {
    this.$store.dispatch("dataComponent/set", ["nowIdMenuClicked", 1]);
    this.$_generalMixin_setIsLoading(false);
  },
  methods: {
    buttonRegisUpdateLinkText: function (linkBitly) {
      if (linkBitly.trim()) {
        return "Update Link";
      }
      return "Refresh Link";
    },
    functionFilterTable: function (filterValue, row) {
      filterValue = filterValue.toLowerCase();
      return (
        row.nama.toString().toLowerCase().includes(filterValue) ||
        row.hp.toLowerCase().includes(filterValue) ||
        row.email.toLowerCase().includes(filterValue)
      );
    },
    doRefreshLink: async function () {
      this.$_generalMixin_setIsLoading(true);
      try {
        let responseUpdate = await MarketerApi.refreshLinkBitly();
        if (responseUpdate.status == 200) {
          this.flash("Berhasil Refresh link bitly", "success");
        } else {
          let errorMessage = responseUpdate.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.$_generalMixin_setIsLoading(false);
    },
    doUpdateLink: async function () {
      this.$_generalMixin_setIsLoading(true);
      let rawInput = { path: this.path };
      try {
        let responseUpdate = await MarketerApi.updateLinkBitly(
          this.idMarketer,
          rawInput
        );
        if (responseUpdate.status == 200) {
          this.flash("Berhasil Update link bitly", "success");
          this.onClickFilter();
        } else {
          let errorMessage = responseUpdate.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.$_generalMixin_setIsLoading(false);
      this.showModalCustomBitly = false;
    },
    doUpdateMarketer: async function () {
      this.$_generalMixin_setIsLoading(true);

      try {
        let rawInput = {
          nama: this.updatedMarketer.nama,
          email: this.updatedMarketer.email,
          hp: this.updatedMarketer.hp,
          is_active: this.updatedMarketer.status,
        };

        let responseUpdateStatus = await MarketerApi.updateMarketerById(
          this.updatedMarketer.id,
          rawInput
        );
        if (responseUpdateStatus.status == 200) {
          let pesanUpdate = `Berhasil melakukan update pada marketer.`;
          this.flash(pesanUpdate, "success");
          await this.onClickFilter();
        } else {
          let errorMessage = responseUpdateStatus.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.$_generalMixin_setIsLoading(false);
      this.showModalEditMarketer = false;
    },
    onChangeUploadFile: function () {
      if (event.target.files[0]) {
        this.fileUpload = event.target.files[0];
      } else {
        this.fileUpload = null;
      }
    },
    onClickAdd: async function () {
      this.$_generalMixin_setIsLoading(true);
      try {
        let formData = new FormData();
        formData.append("nama", this.selectedMarketer.nama);
        formData.append("email", this.selectedMarketer.email);
        formData.append("hp", this.selectedMarketer.noHandphone);

        let responseInsert = await MarketerApi.insertMarketer(formData);
        if (responseInsert.status == 200) {
          this.flash("Berhasil menambahkan Markerter", "success");
        } else {
          let errorMessage = responseInsert.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      this.$_generalMixin_setIsLoading(false);
      await this.onClickFilter();
    },
    onClickFilter: async function () {
      this.$_generalMixin_setIsLoading(true);

      // add data
      this.reqTableDoneMarketer = false;
      this.detailsTableMarketer = [];
      try {
        let responseMarketer = await MarketerApi.getAllMarketerByStatus(
          this.selectedStatusAktif == "Aktif" ? 1 : 0
        );
        if (responseMarketer.status == 200) {
          let dataMarketer = responseMarketer.data.data;
          this.detailsTableMarketer = dataMarketer.map(
            ({ id, nama, hp, email, kode_referral, url_bitly, is_active }) => {
              return {
                id,
                nama,
                hp,
                email,
                kode_referral,
                link_bitly: url_bitly,
                status: is_active ? true : false,
              };
            }
          );
        } else {
          let errorMessage = responseMarketer.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }

      this.reqTableDoneMarketer = true;
      this.$_generalMixin_setIsLoading(false);
    },
    onClickRegisterUpdateLink: async function (row) {
      this.idMarketer = row["id"];
      if (row["link_bitly"].trim()) {
        // update link
        this.path = "";
        // show modal
        this.showModalCustomBitly = true;
      } else {
        // refresh
        await this.doRefreshLink();
        this.onClickFilter();
      }
    },
    onClickEdit: function (row) {
      // show Modal
      this.showModalEditMarketer = true;

      this.updatedMarketer = {
        ...row,
      };
    },
    onClickProsesImport: async function () {
      this.$_generalMixin_setIsLoading(true);
      try {
        let formData = new FormData();
        if (
          this.fileUpload &&
          !this.$_generalMixin_cekObjEmpty(this.fileUpload)
        ) {
          formData.append("file_import", this.fileUpload, this.fileUpload.name);
        }

        let responseProses = await MarketerApi.importMarketer(formData);
        if (responseProses.status == 200) {
          let pesan = responseProses.data.message;
          if (pesan.includes("https")) {
            const linkElement = document.createElement("a");
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            let url = "";
            pesan.replace(urlRegex, (it) => {
              url = it;
            });
            linkElement.href = url;
            linkElement.target = "_blank";

            pesan = pesan.replace("https", "<a href='https");
            pesan = pesan.replace(
              ".xlsx",
              ".xlsx' target='_blank'> Log Import</a>"
            );
            this.flash(pesan, "warning", {
              timeout: 10000,
            });

            linkElement.click();
          } else {
            this.flash(pesan, "success");
          }
        } else {
          let errorMessage = responseProses.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
      await this.onClickFilter();
      this.$_generalMixin_setIsLoading(false);
    },
    onClickExport: function () {
      this.$_generalMixin_setIsLoading(true);
      const linkElement = document.createElement("a");
      linkElement.href = MarketerApi.getExportMarketerByStatus(
        this.selectedStatusAktif == "Aktif" ? 1 : 0
      );
      linkElement.target = "_blank";
      linkElement.click();
      this.flash("Export Berhasil", "success");
      this.$_generalMixin_setIsLoading(false);
    },
  },
};
</script>
<style lang="scss">
.konfigurasi-marketer {
  .download-template {
    font-size: 11pt;
    text-align: right;
    width: 100%;
    align-self: center;
    display: inline-block;
  }
  .choose-file-input .form-control-file {
    padding: 5px;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
  }
  .note {
    color: red;
    font-size: 12px;
    margin-left: 10px;
  }

  .base-smart-table {
    table {
      display: block;
      width: 100%;
      overflow: auto;
      word-break: normal;
      word-break: keep-all;
      font-size: 11px;
    }

    table th {
      font-weight: bold;
    }

    table th,
    table td {
      padding: 0.5rem 1rem;
      border: 1px solid #e9ebec;
      word-break: break-word;
      white-space: pre;
    }

    table tr.section {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
}
</style>